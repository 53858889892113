import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import { Lesson, User } from "../../types/types";
import Card from "../../components/Card/Card";
import { getFBLesson, saveUser } from "../../firebase";

import "./quiz.css";
import { AEButton } from "../../components/Button/Button";

export const QuizPage: React.FC<{ user: User }> = ({ user }) => {
  const params = useParams<"ids">();
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [lesson, setLesson] = useState<Lesson>();

  const handleExitQuiz = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSelect = useCallback((e) => {
    setSelectedAnswer(e.target.id);
  }, []);

  const handleSubmit = useCallback(async () => {
    const success = lesson?.quiz.answer === selectedAnswer;
    if (success) {
      user.score += 2;
      await saveUser(user);
    }
    navigate(`/result/${lesson?.quiz.answer === selectedAnswer}`);
  }, [lesson?.quiz.answer, navigate, selectedAnswer, user]);

  useEffect(() => {
    if (params.ids) {
      const [categoryId, lessonId] = params.ids?.split("-");
      const fetchLesson = async () => {
        const data = await getFBLesson(categoryId, lessonId);
        setLesson(data);
      };

      fetchLesson();
    }
  }, [params.ids]);

  return (
    <>
      {lesson && (
        <div className="quiz-page">
          <div className="quiz-page__heading">
            <AiOutlineClose size={24} onClick={handleExitQuiz} />
            <div className="quiz-page__title">{lesson?.name}</div>
          </div>
          <Card>
            <>
              <h2>{lesson.quiz.question}</h2>
              <form className="quiz-page__form">
                {lesson.quiz?.options.map((option) => (
                  <label key={option.id}>
                    <input
                      type="radio"
                      id={option.id}
                      name={option.description}
                      checked={selectedAnswer === option.id}
                      onChange={handleSelect}
                    ></input>
                    {option.description}
                  </label>
                ))}
              </form>
            </>
          </Card>
          <div className="d-grid gap-2">
            <AEButton disabled={!selectedAnswer} onClick={handleSubmit}>
              Submit
            </AEButton>
          </div>
        </div>
      )}
    </>
  );
};
