import React from "react";
import { Lesson } from "../../types/types";
import "./lessons.css";

export const LessonDetails: React.FC<{ lesson: Lesson }> = ({ lesson }) => {
  return (
    <div className="card lesson__card">
      <div className="lesson__card-name">{lesson.name}</div>
    </div>
  );
};
