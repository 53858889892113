import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import { useNavigate } from "react-router";
import Card from "../../components/Card/Card";
import { getTopUsers } from "../../firebase";
import { User } from "../../types/types";

import "./scoreboard.css";

interface ScoreboardProps {
  user: User;
}

export const Scoreboard: React.FC<ScoreboardProps> = ({ user }) => {
  const navigate = useNavigate();
  const [topUsers, setTopUsers] = useState<User[]>([]);

  const handleExit = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getTopUsers();
      console.log({ users });
      setTopUsers(users);
    };
    fetchUsers();
  }, []);

  return (
    <div className="score-page">
      <div className="score-page__heading">
        <AiOutlineClose size={24} onClick={handleExit} />
        <div className="score-page__title">Top weekly gainers</div>
      </div>
      <Card className="score-page__card">
        <>
          <div className="score-page__card-title">
            <div>Team Member</div>
            <div>Coins</div>
          </div>
          <div className="score-page__card-list">
            {topUsers?.map((tUser) => (
              <div key={tUser.id} className="score-page__card-item">
                <BsPersonCircle size={32} style={{ marginLeft: 12 }} />
                <div style={{ flex: 1 }}>{tUser.displayName}</div>
                <strong>{tUser.score}</strong>
              </div>
            ))}
          </div>
        </>
      </Card>
    </div>
  );
};
