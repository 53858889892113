import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Dashboard } from "./pages/Dashboard/Dashboard";
import { LessonPage } from "./pages/Dashboard/Lesson/Lesson";
import { Login } from "./pages/Login/Login";
import { QuizPage } from "./pages/Quiz/Quiz";
import { Result } from "./pages/Result/Result";
import { Scoreboard } from "./pages/Scoreboard/Scoreboard";

import { auth, authUserToDBUser, getUser, saveUser } from "./firebase";
import { User } from "./types/types";

function App() {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        let dbUser = await getUser(authUser.uid);
        if (!dbUser) {
          dbUser = authUserToDBUser(authUser);
          await saveUser(dbUser);
        }
        dbUser.id = authUser?.uid;
        setUser(dbUser);
      }
    });
  }, []);

  if (user) {
    return (
      <>
        <Routes>
          <Route path="/" element={<Dashboard user={user} />} />
          <Route path="/login" element={<Login user={user} />} />
          <Route path="/lesson/:ids" element={<LessonPage />} />
          <Route path="/quiz/:ids" element={<QuizPage user={user} />} />
          <Route path="/result/:result" element={<Result />} />
          <Route path="/scoreboard" element={<Scoreboard user={user} />} />
        </Routes>
      </>
    );
  } else {
    return (
      <Routes>
        <Route path="/*" element={<Login />} />
      </Routes>
    );
  }
}

export default App;
