import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Category, User } from "../../types/types";
import { CategoryDetails } from "../../components/Categories/Category";
import { getFBCategories } from "../../firebase";
import { Header } from "../../components/Navbar/Navbar";

export const Dashboard: React.FC<{ user: User }> = ({ user }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const data = await getFBCategories();
      setCategories(data);
    };

    fetchCategories();
  }, []);

  return (
    <div>
      <Header user={user} />
      <div className="welcome-box content">
        <p className="welcome-quote">
          <strong>
            It feels good to be lost in the right direction.
            <br />~ Ancient Proverb
          </strong>
        </p>
        <p className="welcome-message">
          Challenge your wits & win $AE COIN for swaggy prizes. Impress Judd so
          much he gives you equity in AE!.. nolegalpromises.com
        </p>
      </div>
      <div>
        {categories.map((category) => (
          <CategoryDetails
            key={`${category.id}${category.name}`}
            category={category}
          />
        ))}
      </div>
    </div>
  );
};
