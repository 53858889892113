import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, User as AuthUser } from "firebase/auth";
import { getDatabase, orderByChild, limitToLast, ref, child, query, get, set } from 'firebase/database';
import { Category, Lesson, User } from './types/types';

const firebaseConfig = {
  apiKey: "AIzaSyALEEolOOQVHaHXkJ4JmtL4ZykuyXvXc6o",
  authDomain: "ae-gamified.firebaseapp.com",
  projectId: "ae-gamified",
  storageBucket: "ae-gamified.appspot.com",
  messagingSenderId: "184004471483",
  appId: "1:184004471483:web:2e2b2e5c7651fcb509979e",
  measurementId: "G-Z0XTKSWZKH"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const googleProvider = new GoogleAuthProvider();

const db = getDatabase(app);

export async function getFBCategories(): Promise<Category[]> {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, 'categories'));
  return snapshot.val()
}

export async function getFBLesson(categoryId: string, lessonId: string): Promise<Lesson> {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `categories/${categoryId}/lessons/${lessonId}`));
  return snapshot.val()
}

export async function saveUser(user: User): Promise<void> {
  const dbRef = ref(db);
  await set(child(dbRef, `users/${user.id}`), user);
}

export async function getUser(uid: string): Promise<User> {
  const dbRef = ref(db);
  const snapshot = await get(child(dbRef, `users/${uid}`));
  return snapshot.val()
}

export async function getTopUsers(): Promise<User[]> {
  const usersRef = ref(db, 'users');
  const usersQuery = await query(usersRef, orderByChild('score'), limitToLast(10));
  const snapshot = await get(usersQuery);
  return Object.values(snapshot.val());
}

export function authUserToDBUser(user: AuthUser): User {
  return {
    id: user.uid,
    displayName: user.displayName ?? '',
    score: 0,
    username: user.email ?? ''
  }
}