import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Carousel } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Lesson } from "../../../types/types";
import Card from "../../../components/Card/Card";
import { getFBLesson } from "../../../firebase";

import "./lesson.css";
import { AEButton } from "../../../components/Button/Button";

export const LessonPage: React.FC = () => {
  const params = useParams<"ids">();
  const navigate = useNavigate();
  const [lesson, setLesson] = useState<Lesson>();
  const [index, setIndex] = useState(0);

  const endOfSlides = useMemo(() => {
    if (!lesson) return 0;

    return lesson.topics.length - 1;
  }, [lesson]);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const handleExitQuiz = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (params.ids) {
      const [categoryId, lessonId] = params.ids?.split("-");
      const fetchLesson = async () => {
        const data = await getFBLesson(categoryId, lessonId);
        setLesson(data);
      };

      fetchLesson();
    }
  }, [params.ids]);

  return (
    <div className="lesson-page">
      <div className="lesson-page__heading">
        <AiOutlineClose size={24} onClick={handleExitQuiz} />
        <div className="lesson-page__title">{lesson?.name}</div>
      </div>
      <Carousel
        interval={index < endOfSlides ? 3000 : null}
        variant="dark"
        className="lesson-page__carousel"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {lesson?.topics.map((topic) => (
          <Carousel.Item key={topic.id}>
            <Card
              name={topic.name}
              description={topic.description}
              imageUrl={topic.imageUrl}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="d-grid gap-2">
        <Link to={`/quiz/${params.ids}`} className="lesson-page__link">
          <AEButton>Quiz me</AEButton>
        </Link>
      </div>
    </div>
  );
};
