import React from "react";
import { Link } from "react-router-dom";
import { Category } from "../../types/types";
import { LessonDetails } from "../Lessons/Lesson";
import "./categories.css";

export const CategoryDetails: React.FC<{ category: Category }> = ({
  category,
}) => {
  return (
    <div className="lessons-category">
      <h2 className="welcome-box">{category.name}</h2>
      <div className="scrolling-wrapper">
        {category.lessons.map((lesson) => (
          <Link
            to={`/lesson/${category.id}-${lesson.id}`}
            style={{ color: "white", textDecoration: "none" }}
            key={`${lesson.id}${lesson.name}`}
          >
            <LessonDetails lesson={lesson} />
          </Link>
        ))}
      </div>
    </div>
  );
};
