import {
  getRedirectResult,
  signInWithRedirect,
  setPersistence,
  browserLocalPersistence,
} from "@firebase/auth";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { AEButton } from "../../components/Button/Button";
import { Header } from "../../components/Navbar/Navbar";
import {
  auth,
  authUserToDBUser,
  googleProvider,
  saveUser,
} from "../../firebase";
import { User } from "../../types/types";

import "./login.css";

export const Login: React.FC<{ user?: User }> = ({ user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  const handleLogin = useCallback(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return signInWithRedirect(auth, googleProvider);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });

    getRedirectResult(auth)
      .then((result) => {
        if (!result) return;

        // The signed-in user info.
        const user = authUserToDBUser(result.user);
        saveUser(user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(errorCode, errorMessage);
      });
  }, []);

  return (
    <div className="login-page">
      <Header />
      <div className="welcome-box">
        <h1>Hurrah!</h1>
        <h1>You don't suck.</h1>

        <p>
          <strong>You're either a genius or failed genius!</strong>
          <br />
          No matter, you've joined a gang of human agency rabble-rousers
          determined to defeat the evil forces that lurk on the interwebs and
          give rise to the BCI prophecy.
        </p>
      </div>
      <div className="welcome-box">
        <AEButton onClick={handleLogin}>Get Agency</AEButton>
      </div>
      <div></div>
    </div>
  );
};
