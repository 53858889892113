import React, { useCallback, useMemo } from "react";
import success from "../../success.svg";
import fail from "../../fail.svg";
import { useNavigate, useParams } from "react-router";
import Card from "../../components/Card/Card";

import "./result.css";
import { AEButton } from "../../components/Button/Button";

export const Result: React.FC = () => {
  const params = useParams<"result">();
  const navigate = useNavigate();

  const imageData = useMemo(() => {
    return params.result === "true"
      ? {
          src: success,
          alt: "success",
        }
      : {
          src: fail,
          alt: "fail",
        };
  }, [params]);

  const mainMessage = useMemo(() => {
    return params.result === "true" ? "Nice Job!" : "Halt!";
  }, [params]);

  const secondaryMessage = useMemo(() => {
    return params.result === "true"
      ? "You Earned 2 AE Shillings"
      : "Who goes there";
  }, [params]);

  const handleNextLesson = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleReviewLesson = useCallback(() => {
    navigate(-2);
  }, [navigate]);

  const handleScoreboard = useCallback(() => {
    navigate("/scoreboard");
  }, [navigate]);

  return (
    <div className="result-page">
      <Card>
        <div className="result-page__card">
          <img src={imageData.src} alt={imageData.alt} />
          <h1>{mainMessage}</h1>
          <p>{secondaryMessage}</p>
        </div>
      </Card>
      <div className="d-grid gap-2">
        {params.result === "true" && (
          <>
            <AEButton onClick={handleNextLesson}>You may proceed</AEButton>
            <AEButton
              className="result-page__button-outline"
              onClick={handleScoreboard}
            >
              Collect your shillings
            </AEButton>
          </>
        )}
        {params.result !== "true" && (
          <AEButton onClick={handleReviewLesson}>
            Only the worthy shall pass
          </AEButton>
        )}
      </div>
    </div>
  );
};
