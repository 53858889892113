import React, { ButtonHTMLAttributes } from "react";
import cs from "classnames";
import "./button.css";

type AEButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const AEButton: React.FC<AEButtonProps> = (props) => {
  const classes = cs([props.className, "gradient-button"]);
  return (
    <button {...props} className={classes}>
      {props.children}
    </button>
  );
};
