import React from "react";
import cs from "classnames";
import { Image } from "react-bootstrap";
import { AiFillHeart } from "react-icons/ai";
import "./card.css";

interface CardProps {
  name?: string;
  description?: string;
  imageUrl?: string;
  children?: JSX.Element | string;
  className?: string;
}

const Card: React.FC<CardProps> = ({
  name,
  description,
  imageUrl,
  children,
  className,
}) => {
  const classes = cs(["topic-card", className]);
  const img =
    imageUrl || "https://images.unsplash.com/photo-1481349518771-20055b2a7b24";
  return (
    <div className={classes}>
      {!!children ? (
        children
      ) : (
        <>
          <h3>
            <AiFillHeart color="#FA6764" />
            {name}
          </h3>
          <p>{description}</p>
          <Image className="topic-card__img" src={img} alt={name} fluid />
        </>
      )}
    </div>
  );
};

export default Card;
