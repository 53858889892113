import React, { useCallback } from "react";
import { FaCoins } from "react-icons/fa";
import { BsPersonCircle } from "react-icons/bs";
import { Navbar, Container } from "react-bootstrap";

import logo from "../../logo.svg";
import { User } from "../../types/types";
import { useNavigate } from "react-router";

interface HeaderProps {
  user?: User;
}

export const Header: React.FC<HeaderProps> = ({ user }) => {
  const navigate = useNavigate();

  const handleCoinsClick = useCallback(() => {
    navigate("/scoreboard");
  }, [navigate]);

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>
            <img
              alt=""
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Agency Gamer
          </Navbar.Brand>
          {user && (
            <>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 6,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }}
                      onClick={handleCoinsClick}
                    >
                      <span style={{ fontSize: 22 }}>{user.score}</span>{" "}
                      <FaCoins size={18} />
                    </div>
                    <BsPersonCircle size={32} style={{ marginLeft: 12 }} />
                  </div>
                </Navbar.Text>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
    </>
  );
};
